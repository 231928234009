import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyC2sx1T_GfPtcsdUh8HJEB_kPcRhHLXnAE",
  authDomain: "dgamerz.firebaseapp.com",
  databaseURL: "https://dgamerz.firebaseio.com",
  projectId: "dgamerz",
  storageBucket: "dgamerz.appspot.com",
  messagingSenderId: "1070285236681",
  appId: "1:1070285236681:web:9216ec46a4a327e35b6018",
  measurementId: "G-PP3EWJKJCC",
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

const FileUpload = ({ onImagesUploaded, productId }) => {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [previewUrls, setPreviewUrls] = useState([]);
  const [error, setError] = useState("");

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    
    // Validate file types
    const validFiles = selectedFiles.filter(
      file => file.type === "image/jpeg" || file.type === "image/png"
    );
    
    if (validFiles.length !== selectedFiles.length) {
      setError("Only JPEG and PNG images are allowed");
    } else {
      setError("");
    }
    
    setFiles(validFiles);
    
    // Create preview URLs
    const newPreviewUrls = validFiles.map(file => URL.createObjectURL(file));
    
    // Clean up previous preview URLs to avoid memory leaks
    previewUrls.forEach(url => URL.revokeObjectURL(url));
    
    setPreviewUrls(newPreviewUrls);
  };

  const removeFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
    
    // Remove preview
    URL.revokeObjectURL(previewUrls[index]);
    const newPreviewUrls = [...previewUrls];
    newPreviewUrls.splice(index, 1);
    setPreviewUrls(newPreviewUrls);
  };

  const uploadFiles = async () => {
    if (files.length === 0) {
      setError("Please select files to upload");
      return;
    }
    
    setUploading(true);
    const uploadPromises = files.map((file, index) => {
      // Create unique filename with productId
      const fileExtension = file.name.split('.').pop();
      const fileName = `products/${productId}-${Date.now()}-${index}.${fileExtension}`;
      const storageRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(storageRef, file);
      
      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(prev => ({
              ...prev,
              [index]: progress
            }));
          },
          (error) => {
            console.error("Upload error:", error);
            setError(`Upload failed: ${error.message}`);
            reject(error);
          },
          async () => {
            try {
              const url = await getDownloadURL(uploadTask.snapshot.ref);
              resolve({ 
                url, 
                fileName, 
                originalName: file.name,
                size: file.size
              });
            } catch (error) {
              reject(error);
            }
          }
        );
      });
    });
    
    try {
      const uploadedFiles = await Promise.all(uploadPromises);
      
      // Clean up preview URLs
      previewUrls.forEach(url => URL.revokeObjectURL(url));
      
      // Reset state
      setFiles([]);
      setPreviewUrls([]);
      setUploadProgress({});
      
      // Call the callback with uploaded file info
      onImagesUploaded(uploadedFiles);
      
    } catch (error) {
      console.error("Error in upload process:", error);
      setError("Failed to upload some files. Please try again.");
    } finally {
      setUploading(false);
    }
  };
  
  return (
    <div className="file-upload-container">
      {error && <div className="notification is-danger">{error}</div>}
      
      <div className="field">
        <div className="file has-name is-fullwidth">
          <label className="file-label">
            <input 
              className="file-input" 
              type="file" 
              name="images" 
              accept=".jpg,.jpeg,.png" 
              multiple 
              onChange={handleFileChange}
              disabled={uploading}
            />
            <span className="file-cta">
              <span className="file-icon">
                <i className="fas fa-upload"></i>
              </span>
              <span className="file-label">
                Choose images...
              </span>
            </span>
            <span className="file-name">
              {files.length > 0 
                ? `${files.length} file(s) selected` 
                : "No files selected"}
            </span>
          </label>
        </div>
      </div>
      
      {previewUrls.length > 0 && (
        <div className="preview-container" style={{ display: "flex", flexWrap: "wrap", gap: "10px", marginTop: "10px" }}>
          {previewUrls.map((url, index) => (
            <div key={index} className="preview-item position-relative" style={{ position: "relative" }}>
              <img 
                src={url} 
                alt={`Preview ${index}`} 
                style={{ width: "100px", height: "100px", objectFit: "cover" }} 
              />
              <span 
                onClick={() => removeFile(index)} 
                className="delete position-absolute top-0 end-0"
                style={{ position: "absolute", top: "0", right: "0", cursor: "pointer" }}
              ></span>
              {uploading && uploadProgress[index] !== undefined && (
                <progress 
                  className="progress is-primary" 
                  value={uploadProgress[index]} 
                  max="100"
                  style={{ width: "100px", marginTop: "5px" }}
                >
                  {Math.round(uploadProgress[index])}%
                </progress>
              )}
            </div>
          ))}
        </div>
      )}
      
      <div className="field" style={{ marginTop: "15px" }}>
        <button 
          className={`button is-primary ${uploading ? 'is-loading' : ''}`}
          onClick={uploadFiles}
          disabled={files.length === 0 || uploading}
        >
          Upload All Images
        </button>
      </div>
    </div>
  );
};

export default FileUpload;