import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllProducts } from "../../store/actions/products";
import Error from "../../components/Error/Error";
import Loader from "../../components/Loader/Loader";
import Modal from "../../components/Modal/Modal";
import MyDropdown from "../../components/Dropdown/multiple-dropdown";
import { validateNewStockData } from "../../utils/validators";
import axios from "axios";
import Switch from "../../components/common/switch";
import FileUpload from "../../utils/FileUpload"; 

const ManageStock = ({ getAllProducts }) => {
  const [productData, setProductData] = useState({
    title: "",
    itemNumber: "",
    brand: "",
    price: "",
    currency: "",
    material: "",
    weight: "",
    description: "",
    category: "",
    colors: [],
    sizes: [],
  });
  const [loading, setLoading] = useState(true);
  const [isManageShow, setIsManageShow] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [modalError, setModalError] = useState("");
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState("");
  const { productId } = useParams();
  const [colorsList, setColorList] = useState([]);
  const [sizesList, setSizesList] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [color, setColor] = useState(null);
  const [imagesList, setImagesList] = useState([]);
  const [groupedImagesList, setGroupedImagesList] = useState([]);
  const [isAvailable, setIsAvailable] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);
  const [uploadedImages, setUploadedImages] = useState([]); // State to store uploaded images
  const [editImages, setEditImages] = useState([]); // State to store images for the color being edited

  useEffect(() => {
    if (productId) {
      fetchProductData();
    }
  }, [productId]);

  // Group images by color
  useEffect(() => {
    if (imagesList.length > 0) {
      const grouped = {};
      
      // Group by color
      imagesList.forEach(img => {
        if (!grouped[img.color]) {
          grouped[img.color] = {
            color: img.color,
            sizes: img.sizes || [],
            images: [],
            sortOrder: img.sortOrder || 1,
            default: img.default || false,
            isAvailable: img.isAvailable !== undefined ? img.isAvailable : true
          };
        }
        
        grouped[img.color].images.push(img);
        
        // If any image in the group is default, the group is default
        if (img.default) {
          grouped[img.color].default = true;
        }
        
        // If any image in the group is unavailable, update sizes to merge
        grouped[img.color].sizes = [...new Set([...grouped[img.color].sizes, ...(img.sizes || [])])];
      });
      
      // Convert to array
      const groupedArray = Object.values(grouped);
      setGroupedImagesList(groupedArray);
    } else {
      setGroupedImagesList([]);
    }
  }, [imagesList]);

  const updateList = async (newList) => {
    try {
      setLoading(true);
      const sortedList = newList?.sort((a, b) => {
        return (a?.sortOrder || 0) - (b?.sortOrder || 0);
      });
      const response = await axios.put(`/admin/product/${productId}`, {
        images: sortedList,
      });
      setSuccess(response.data.message);
      setLoading(false);
    } catch (error) {
      setModalError(error.response?.data?.error || "Failed to update product.");
      setLoading(false);
    }
  };
  
  const getMasterList = async (productData) => {
    try {
      setColorList(
        productData?.colors?.map((e) => ({
          name: e,
          id: e,
        }))
      );
      setSizesList(
        productData?.sizes?.map((e) => ({
          name: e,
          id: e,
        }))
      );
    } catch (error) {
      console.error("Error fetching master list:", error);
    }
  };

  const fetchProductData = async () => {
    try {
      const authToken = localStorage.getItem("Authorization");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      const response = await axios.get(`/admin/product/`, {
        params: { productId },
      });

      getMasterList(response?.data);
      setProductData((prevData) => ({
        ...prevData,
        ...response.data,
      }));
      setImagesList(response?.data?.images || []);
      setIsAvailable(response?.data?.isActive || false);
      setLoading(false);
    } catch (error) {
      setErrors({ fetchError: "Failed to load product data." });
      setLoading(false);
    }
  };

  const handleDelete = async (colorToDelete) => {
    // Remove all images with this color
    const newList = imagesList.filter(img => img.color !== colorToDelete);
    setImagesList(newList);
    updateList(newList);
    
    if (newList?.length === 0 && isAvailable) {
      handleStatusToggle();
    }
  };

  const handleEdit = (colorGroup) => {
    // Get all images for this color
    const colorsImages = imagesList.filter(img => img.color === colorGroup.color);
    
    setEdit(true);
    setColor(colorGroup.color);
    setSizes(colorGroup.sizes);
    setSortOrder(colorGroup.sortOrder || 1);
    setEditImages(colorsImages); // Store images for this color
    setIsManageShow(true);
  };

  // New function to remove individual image in edit mode
  const handleRemoveEditImage = (imageToRemove) => {
    // Remove the image from editImages for UI
    const updatedEditImages = editImages.filter(img => img.url !== imageToRemove.url);
    setEditImages(updatedEditImages);
    
    // Also remove from the main imagesList
    const updatedImagesList = imagesList.filter(img => img.url !== imageToRemove.url);
    setImagesList(updatedImagesList);
    
    // If all images for this color are removed, close the edit panel
    if (updatedEditImages.length === 0) {
      setIsManageShow(false);
      setEdit(false);
      updateList(updatedImagesList);
    }
  };

  // Handler for when images are uploaded via FileUpload component
  const handleImagesUploaded = (uploadedFiles) => {
    setUploadedImages(uploadedFiles);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { valid, errors } = validateNewStockData({ color, sizes });

    if (!valid) {
      setErrors(errors);
      return;
    }
    
    if (edit) {
      setLoading(true);
      
      // Get current images for this color that remain after possible deletions
      const remainingImages = imagesList.filter(img => img.color === color);
      
      // In edit mode, we're adding new images to existing ones rather than replacing
      if (uploadedImages.length > 0) {
        // Add new images with this color and settings
        const newImages = uploadedImages.map((image) => ({
          color,
          url: image.url,
          sizes,
          sortOrder: sortOrder,
          default: remainingImages.length === 0 && imagesList.filter(img => img.color !== color).length === 0, // First image is default only if no other images
          isAvailable: true,
          fileName: image.fileName,
          originalName: image.originalName,
          size: image.size
        }));
        
        // Update existing images with new sizes and sort order
        const updatedRemainingImages = remainingImages.map(img => ({
          ...img,
          sizes,
          sortOrder
        }));
        
        // Combine all images
        const otherImages = imagesList.filter(img => img.color !== color);
        const updatedList = [...otherImages, ...updatedRemainingImages, ...newImages];
        
        setImagesList(updatedList);
        updateList(updatedList);
      } else {
        // Just update metadata for existing images
        const updatedList = imagesList.map(img => {
          if (img.color === color) {
            return {
              ...img,
              sizes,
              sortOrder
            };
          }
          return img;
        });
        
        setImagesList(updatedList);
        updateList(updatedList);
      }
      
      setIsManageShow(false);
      return;
    }

    // Not in edit mode, adding new images
    if (uploadedImages.length === 0) {
      setErrors({ image: "Please upload at least one image." });
      return;
    }

    setLoading(true);
    
    try {
      // Check if color already exists
      const colorExists = imagesList.some(img => img.color === color);
      
      if (colorExists) {
        setLoading(false);
        setErrors({ color: "This color already exists. Please edit the existing color instead." });
        return;
      }
      
      // Create new images array with uploaded files - all with the same color
      const newImages = uploadedImages.map((image, idx) => ({
        color,
        url: image.url,
        sizes,
        sortOrder: sortOrder,
        default: idx === 0 && imagesList.length === 0, // First image is default if no images exist
        isAvailable: true,
        fileName: image.fileName,
        originalName: image.originalName,
        size: image.size
      }));

      const updatedList = [...imagesList, ...newImages];
      setImagesList(updatedList);

      // Update product with new images
      const response = await axios.put(`/admin/product/${productId}`, {
        images: updatedList,
      });

      setSuccess(response.data.message);
      setDisplayModal(true);
      
      // Reset states
      setColor(null);
      setSizes([]);
      setUploadedImages([]);
      setErrors({});
      getAllProducts();
      setLoading(false);
      
      setTimeout(() => {
        setDisplayModal(false);
        setIsManageShow(false);
      }, 1500);
    } catch (error) {
      setModalError(error.response?.data?.error || "Failed to update product.");
      setLoading(false);
    }
  };

  const handleToggleDefault = (colorToToggle) => {
    setImagesList((prevList) => {
      const newList = prevList.map((img) => ({
        ...img,
        default: img.color === colorToToggle
      }));
      updateList(newList);
      return newList;
    });
  };

  const handleImageStatusToggle = (colorToToggle) => {
    setImagesList((prevList) => {
      const isCurrentlyAvailable = prevList.find(img => img.color === colorToToggle)?.isAvailable;
      
      const newList = prevList.map((img) => {
        if (img.color === colorToToggle) {
          return {
            ...img,
            isAvailable: !isCurrentlyAvailable
          };
        }
        return img;
      });
      
      updateList(newList);
      return newList;
    });
  };

  const handleStatusToggle = async () => {
    if (imagesList?.length === 0) {
      alert("Please add Items first.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.put(`/admin/productStatus`, {
        productId: productId,
        isActive: !isAvailable,
      });
      setSuccess(response.data.message);
      setIsAvailable(!isAvailable);
      setLoading(false);
      setDisplayModal(true);
      setTimeout(() => {
        getAllProducts();
        setDisplayModal(false);
      }, 1500);
    } catch (error) {
      setModalError(error.response?.data?.error || "Failed to update product.");
      setLoading(false);
    }
  };
  
  const handleAddButton = () => {
    if (isManageShow) {
      setColor(null);
      setSizes([]);
      setUploadedImages([]);
      setErrors({});
      setEdit(false);
      setEditIndex(null);
      setEditImages([]);
    }
    setIsManageShow(!isManageShow);
  };
  
  return (
    <>
      <Layout>
        <Modal active={displayModal} message={success} error={modalError} />
        {loading ? (
          <Loader />
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <h1 className="subtitle">Manage Stock</h1>
                <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                  Product: {productData?.title}
                </p>
              </div>
              <div className="Select avaible">
                <label className="label">Product Status (Draft/Public): </label>
                <Switch
                  id="productStatus"
                  name="productStatus"
                  isOn={isAvailable}
                  handleToggle={() => handleStatusToggle()}
                />
                {!isAvailable && errors.isAvailable && (
                  <Error error={errors.isAvailable} />
                )}
              </div>
              <br />
              <div>
                <button
                  onClick={handleAddButton}
                  type="submit"
                  className="button is-primary"
                  style={{ width: "100%" }}
                >
                  {isManageShow ? "Close" : "Add Item"}
                </button>
              </div>
            </div>
            
            {isManageShow && (
              <div className="columns is-centered">
                <div className="column is-half">
                  {errors.fetchError && <Error error={errors.fetchError} />}
                  <form onSubmit={handleSubmit}>
                    <div className="Select color" style={{ width: "50%" }}>
                      <label className="label">Select Color</label>
                      <MyDropdown
                        value={color}
                        options={colorsList}
                        isMulti={false}
                        setState={setColor}
                        isDisabled={edit} 
                      />
                      {errors.color && <Error error={errors.color} />}
                    </div>
                    <div className="Select color" style={{ width: "70%" }}>
                      <label className="label">Select Sizes</label>
                      <MyDropdown
                        value={sizes}
                        options={sizesList}
                        isMulti={true}
                        setState={setSizes}
                      />
                      {errors.sizes && <Error error={errors.sizes} />}
                    </div>
                    <br />
                    <div className="Select color" style={{ width: "70%" }}>
                      <label className="label">Sort Order</label>
                      <input
                        className="input"
                        placeholder="Sort Order"
                        type="number"
                        name="sortOrder"
                        value={sortOrder}
                        onChange={(e) => setSortOrder(e.target.value)}
                      />
                      {errors.sortOrder && <Error error={errors.sortOrder} />}
                    </div>
                    <br />
                    
                    {/* Show current images in edit mode with delete button */}
                    {edit && editImages.length > 0 && (
                      <div>
                        <label className="label">Current Images ({editImages.length})</label>
                        <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", marginBottom: "15px" }}>
                          {editImages.map((img, idx) => (
                            <div key={idx} style={{ position: "relative" }}>
                              <img 
                                src={img.url} 
                                alt={`${color} - Image ${idx+1}`} 
                                style={{ width: "100px", height: "100px", objectFit: "cover" }} 
                              />
                              <button
                                type="button"
                                onClick={() => handleRemoveEditImage(img)}
                                style={{
                                  position: "absolute",
                                  top: "-8px",
                                  right: "-8px",
                                  width: "24px",
                                  height: "24px",
                                  borderRadius: "50%",
                                  backgroundColor: "#ff3860",
                                  color: "white",
                                  border: "none",
                                  fontSize: "14px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  cursor: "pointer"
                                }}
                              >
                                ✕
                              </button>
                            </div>
                          ))}
                        </div>
                        <p className="help">You can remove individual images or add new ones below.</p>
                      </div>
                    )}
                    
                    {/* FileUpload component */}
                    <div className="field">
                      <label className="label">{edit ? "Add More Images" : "Product Images"}</label>
                      <FileUpload 
                        onImagesUploaded={handleImagesUploaded} 
                        productId={productId} 
                      />
                      {errors.image && <Error error={errors.image} />}
                    </div>
                    
                    {/* Display uploaded images count */}
                    {uploadedImages.length > 0 && (
                      <div className="notification is-info">
                        <p>{uploadedImages.length} new images selected for upload</p>
                      </div>
                    )}
                    
                    <br />
                    <button
                      type="submit"
                      className="button is-primary"
                      style={{ width: "100%" }}
                      disabled={!edit && uploadedImages.length === 0}
                    >
                      {edit ? (
                        editImages.length === 0 && uploadedImages.length === 0 
                          ? "Update Settings" 
                          : "Update"
                      ) : "Add Images"}
                    </button>
                  </form>
                  {loading && <Loader />}
                </div>
              </div>
            )}
            
            {/* Product Images Table with image count */}
            <div style={{ marginTop: "20px", marginBottom: "10px" }}>
              <h3 className="subtitle">
                Product Variations ({groupedImagesList.length})
              </h3>
            </div>
            
            {groupedImagesList && groupedImagesList.length > 0 ? (
              <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                <table
                  className="table"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>Color</th>
                      <th>Sizes</th>
                      <th>Preview</th>
                      <th>Images</th>
                      <th>Sort Order</th>
                      <th>Default</th>
                      <th>Availability</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupedImagesList.map((group, index) => (
                      <tr key={index}>
                        <td>{group.color}</td>
                        <td>{group?.sizes?.join(", ")}</td>
                        <td>
                          <div className="stock-img-container">
                            <img
                              className="stock-img"
                              src={group.images[0]?.url}
                              alt="Product"
                              style={{ width: "60px", height: "60px", objectFit: "cover" }}
                            />
                            <div className="preview">
                              <img src={group.images[0]?.url} alt="Product preview" />
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="tag is-info is-medium">
                            {group.images.length}
                          </span>
                        </td>
                        <td>{group?.sortOrder || ""}</td>
                        <td>
                          <Switch
                            id={`isDefault-${index}`}
                            name="isDefault"
                            isOn={group.default}
                            handleToggle={() => handleToggleDefault(group.color)}
                          />
                        </td>
                        <td>
                          <Switch
                            id={`isAvailable-${index}`}
                            name="isAvailable"
                            isOn={group.isAvailable}
                            handleToggle={() => handleImageStatusToggle(group.color)}
                          />
                        </td>
                        <td>
                          <button
                            onClick={() => handleEdit(group)}
                            className="button is-link"
                          >
                            Edit
                          </button>
                        </td>
                        <td>
                          <button
                            onClick={() => handleDelete(group.color)}
                            className="button is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="notification is-warning">No images found for this product.</div>
            )}
          </div>
        )}
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getAllProducts: () => dispatch(getAllProducts()),
});

export default connect(null, mapDispatchToProps)(ManageStock);